<template>
  <!--    <h1>Frontends</h1>-->
  <router-view />
</template>

<script>
export default {
  name: "Frontends",
}
</script>

<style scoped></style>
